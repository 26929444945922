/* apply wild styling to search input */
.epr-search-container > input {
  @apply relative z-0 block w-full !appearance-none !border-0 !border-transparent bg-surface-default !text-sm text-text-default !ring-1 !ring-inset !ring-border-general-default !transition-none placeholder:text-sm placeholder:text-text-subdued focus-within:!bg-surface-default focus:!ring-2 focus:!ring-inset focus:!ring-base-primary disabled:cursor-not-allowed disabled:bg-surface-disabled disabled:text-text-disabled disabled:ring-border-general-disabled disabled:placeholder:text-text-disabled;
}

.epr-header > div {
  @apply p-2.5;
}

/* hide skin tones */
.epr-skin-tones {
  @apply !hidden;
}

/* hide body scrollbar */
.epr-body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* hide body scrollbar */
.epr-body::-webkit-scrollbar {
  display: none; /* Webkit-based browsers */
}
